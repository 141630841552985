import React from 'react';
import { useInView } from 'react-intersection-observer';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';

import Town from './hero/town';
import Stars from '../../images/01-origins/hero-stars.inline.svg';
import Moon from '../../images/01-origins/hero-moon.inline.svg';

import ScrollTracker from '../scroll-tracker';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile
          ? 0
          : top < height && top > -height
          ? top
          : 0;

        return (
          <div className="hero bg-navy">
            <div className="bg">
              <div className="side-block hidden md:block w-1/4" />
              <div className="relative w-full md:w-3/4 gradient">
                <div
                  className="absolute pin-r pin-t mr-24 mt-64"
                  style={{
                    transform: `translateZ(0) translateY(${-offsetY / 8}px)`,
                  }}
                >
                  <Stars />
                </div>
                <div
                  ref={ref1}
                  className={`absolute pin-x mx-auto animated ${
                    inView1 ? 'fadeIn' : 'fadeOut'
                  }`}
                  style={{
                    marginTop: '8.8888888889vw',
                    width: '38.19444444vw',
                    transform: `translateZ(0) translateY(${offsetY / 5}px)`,
                  }}
                >
                  <Moon width="100%" />
                </div>
                <div
                  id="town-light"
                  ref={ref3}
                  className="absolute pin-b w-full pin-r"
                  style={{ maxWidth: 716 }}
                >
                  <Town inView={inView3} />
                </div>
              </div>
            </div>
            <div className="hero-text-container" style={{ bottom: '42%' }}>
              <HeroText
                date="February 2012"
                lineA="The Origins of"
                lineB="Afterpay"
              />
              <HighlightTextHeading isChapter1>
                Late one night in 2012
              </HighlightTextHeading>
              <div className="absolute pin-t-100 pt-12 sm:pt-16 xl:pt-20 pl-4 pr-8 sm:pl-10 md:pl-16 lg:pl-20 xl:pl-24 sm:pr-10 xl:pl-16">
                <div className="max-w-sm body-text text-white">
                  <p
                    ref={ref2}
                    className={`animated ${inView2 ? 'fadeIn' : 'fadeOut'}`}
                  >
                    Late one night in 2012, Anthony Eisen was sitting in his
                    Sydney home working late, busily restructuring the assets of
                    the investment company Guinness Peat Group, for which he was
                    the Chief Investment Officer. He noticed activity in the lit
                    window of his neighbours house, and wondered - who was this
                    fellow night owl always working so late?
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero, '200vh');
