import React from 'react';

import Hero from '../../components/01-origins/hero';
import Walking from '../../components/01-origins/walking';
import Hundred from '../../components/01-origins/hundred';
import Afterpay from '../../components/01-origins/afterpay';
import Billion from '../../components/01-origins/billion';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <div className="nav-text">
        <div>01. The Origins of Afterpay</div>
      </div>
      <Hero />
      <Walking />
      <Hundred />
      <Afterpay />
      <Billion />
    </div>
  );
};
