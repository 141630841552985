/* global ga*/

import React, { Component } from 'react';

import Input from './input';
import LoadingSpinner from './loading-spinner';

import loadLinkedinImage, {
  linkedInEvents,
} from '../utils/load-linkedin-image';

class EnquiryForm extends Component {
  static checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  state = {
    contactForm: {
      name: {
        label: 'NAME',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'John Smith',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      reply_to: {
        label: 'EMAIL',
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'jsmith@yourcompany.com',
        },
        value: '',
        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
      company: {
        label: 'COMPANY',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Company Name',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      telephone: {
        label: 'TELEPHONE',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: '+64 9 123 4567',
        },
        value: '',
        validation: {
          required: true,
          minLength: 7,
          maxLength: 20,
          isNumeric: true,
        },
        valid: false,
        touched: false,
      },
      description: {
        label: 'DESCRIPTION',
        elementType: 'textarea',
        elementConfig: {
          placeholder: 'How can we help?',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
    formIsValid: false,
    sendSuccess: false,
    sendError: false,
    sending: false,
  };

  componentDidMount() {
    if (typeof ga !== 'undefined')
      ga('send', 'event', 'link', 'click', 'afterpay_story_start_a_project');

    loadLinkedinImage(linkedInEvents.workWith);
  }

  handleSubmit = e => {
    e.preventDefault();

    const { contactForm } = this.state;
    const data = {};

    Object.entries(contactForm).forEach(([key]) => {
      data[key] = contactForm[key].value;
    });

    const { form } = this;

    this.setState({ sending: true });

    const xhr = new XMLHttpRequest();

    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    // Send the collected data as JSON
    xhr.send(JSON.stringify(data));

    // Callback function
    xhr.onloadend = response => {
      if (response.target.status === 200) {
        // The form submission was successful
        this.setState({ sendSuccess: true, sending: false });
      } else {
        // The form submission failed
        this.setState({ sendError: true, sending: false });
      }

      if (typeof ga !== 'undefined')
        ga(
          'send',
          'event',
          'link',
          'click',
          'afterpay_story_submit_new_project'
        );

      loadLinkedinImage(linkedInEvents.formSubmit);
    };
  };

  inputChangedHandler = (event, inputIdentifier) => {
    const { contactForm } = this.state;

    const updatedContactForm = {
      ...contactForm,
    };
    const updatedFormElement = {
      ...updatedContactForm[inputIdentifier],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = EnquiryForm.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedContactForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;

    Object.entries(updatedContactForm).forEach(([key]) => {
      formIsValid = updatedContactForm[key].valid && formIsValid;
    });

    this.setState({
      contactForm: updatedContactForm,
      formIsValid,
    });
  };

  render() {
    const {
      contactForm,
      formIsValid,
      sendSuccess,
      sendError,
      sending,
    } = this.state;
    const formElements = [];

    Object.entries(contactForm).forEach(([key]) => {
      formElements.push({
        id: key,
        config: contactForm[key],
      });
    });

    let component;

    if (sendSuccess) {
      component = (
        <div className="bg-black p-32 text-white text-center">
          Thank you for your request. We will be in touch shortly.
        </div>
      );
    } else if (sendError) {
      component = (
        <div className="bg-black p-32 text-white text-center">
          Opps! There was an error sending your request.
        </div>
      );
    } else if (sending) {
      component = (
        <div className="bg-black p-32 text-white text-center">
          <LoadingSpinner />
        </div>
      );
    } else {
      component = (
        <form
          action="https://0d45fp7kvj.execute-api.us-west-2.amazonaws.com/default/dovetail-website-ses-mailer-dev-websiteMailer"
          method="POST"
          onSubmit={this.handleSubmit}
          ref={el => {
            this.form = el;
          }}
          className="lg:py-12 lg:px-8 py-4 my-16 bg-ash-black text-white"
        >
          {formElements.map(formElement => (
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
              label={formElement.config.label}
            />
          ))}

          <div className="flex px-4">
            <button
              type="submit"
              className={`ml-auto btn p-4 text-ash-black bg-turquoise text-xl ${
                formIsValid ? 'opacity-1' : 'opacity-50'
              }`}
              disabled={!formIsValid}
            >
              Submit
            </button>
          </div>
        </form>
      );
    }

    return <div className="w-full px-6">{component}</div>;
  }
}

export default EnquiryForm;
