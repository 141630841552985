import React from 'react';
import { useInView } from 'react-intersection-observer';

import One from '../../images/01-origins/hundred-one.inline.svg';
import Zero from '../../images/01-origins/hundred-zero.inline.svg';
import Polycluster from '../../images/01-origins/hundred-polycluster.inline.svg';
import CircleSVG from '../../images/circle.inline.svg';

export default () => {
  const [refCircle, inViewCircle] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refOne, inViewOne] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refZero, inViewZero] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div>
      <div className="lg:pb-64 px-6 sm:px-12 md:px-20 lg:px-40">
        <div
          id="hundred-top-section"
          ref={refCircle}
          className={`relative ${
            inViewCircle ? 'in-view' : ''
          }`}
        >
          <div className="flex max-w-xl mx-auto relative h-48 my-16">
            <div className="lg:w-1/4" />
            <div
              id="hundred-line"
              className="relative w-px h-0 bg-neon-lavender mx-auto lg:ml-8"
            />
          </div>
          <div className="relative max-w-xl mx-auto">
            <CircleSVG
              id="hundred-circle"
              className="block absolute pin-t pin-l -mt-10 -ml-48 lg:ml-20"
              width={376}
              fill="#BADAF8"
            />
            <p
              id="hundred-text"
              className={`relative z-10 highlight-text-expanded text-navy text-right lg:pt-12 animated ${inViewCircle ? 'fadeInUpSmall' : 'fadeOutUpSmall'}`}
            >
              One thing, however, was frustrating him;{' '}
              <span className="text-special-red">of 100 people</span> that
              visited the site{' '}
              <span className="text-special-red">
                only 1 would actually make a purchase.
              </span>{' '}
              This is not unusual for e-commerce, but it was crushing the
              margins of his jewellery business.
            </p>
            <Polycluster className="block lg:absolute my-32 mx-auto lg:pin-t lg:pin-r lg:mb-0 lg:-mt-56" />
          </div>
        </div>
      </div>
      <div className="relative z-10 max-w-2xl mx-auto hidden xl:block">
        <div className="flex">
          <div
            ref={refOne}
            id="giant-one"
            className={`ml-0 ${inViewOne ? 'in-view' : ''} mr-16`}
          >
            <One className="block" />
          </div>
          <div className="red-zero flex-no-shrink mr-16" />
          <div
            ref={refZero}
            id="giant-zero"
            className={`relative ${inViewZero ? 'in-view' : ''}`}
            style={{ marginBottom: -154 }}
          >
            <Zero style={{ display: 'block' }} />
          </div>
        </div>
      </div>
    </div>
  );
};
