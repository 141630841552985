import React from 'react';
import { useInView } from 'react-intersection-observer';

import PolyCluster from '../../images/04-scaling/website-polycluster.inline.svg';
import TransitionSpan from '../transition-span';

export default () => {
  const [refText, inViewText] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refHighlight, inViewHighlight] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="bg-navy py-32 lg:py-48 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48">
      <div className="relative">
        <div className="max-w-md">
          <p
            ref={refText}
            className={`body-text text-turquoise animated ${
              inViewText ? 'fadeIn' : 'fadeOut'
            }`}
          >
            A brief silence followed and laughter filled the room. Afterpay’s
            then Director of Product, David Whiteman, looked at the Doves with a
            smile and simply said ‘Well guys, looks like you’re looking after
            the web platform now’.
          </p>
          <div className="relative">
            <div className="h-48 my-16">
              <div
                className={`w-px ml-12 sm:ml-auto mr-auto bg-special-red ${
                  inViewHighlight ? 'h-48' : 'h-0'
                }`}
                style={{
                  transition: 'height 250ms',
                }}
              />
            </div>
            <PolyCluster className="block absolute pin-y my-auto pin-r mr-12" />
          </div>
        </div>
        <div className="max-w-lg ml-auto">
          <p
            ref={refHighlight}
            className={`highlight-text-expanded text-special-red text-right animated ${
              inViewHighlight ? 'fadeIn' : 'fadeOut'
            }`}
          >
            This marked the beginning of a deep, successful and ongoing
            partnership between Afterpay and Paloma.{' '}
            <TransitionSpan
              inView={inViewHighlight}
              a="text-special-red"
              b="text-turquoise"
              delay={500}
            >
              From 2017 to the present day,{' '}
            </TransitionSpan>
            Paloma has looked after the design and development of Afterpay’s
            web and mobile properties as they have grown to service{' '}
            <TransitionSpan
              inView={inViewHighlight}
              a="text-special-red"
              b="text-turquoise"
              delay={1000}
            >
              over 4.7 million monthly active customers.
            </TransitionSpan>
          </p>
        </div>
      </div>
    </div>
  );
};
