import { useState } from 'react';

export default ({ children }) => {
  const [chapter, setChapter] = useState({ id: '00-home', ratio: 0 });

  // if (typeof window !== 'undefined' && window.history) {
  //   const chapterId = chapter.id === '00-home' ? '' : `#${chapter.id}`;

  //   window.history.pushState({}, chapter.name, `/${chapterId}`);
  // }

  return children({
    activeChapter: chapter,
    setActiveChapter: setChapter,
  });
};
