import React from 'react';
import { useInView } from 'react-intersection-observer';

import PolyCluster from '../../images/04-scaling/intro-polycluster.inline.svg';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="relative flex z-10 pt-32 md:pt-64 pb-24 px-8 sm:px-12 md:px-32 lg:pl-56 xl:pl-64">
      <div className="max-w-md body-text text-navy">
        <p
          ref={ref}
          className={`animated mb-12 ${inView ? 'fadeIn' : 'fadeOut'}`}
        >
          In June of 2017, Afterpay’s web platform was frequently on its knees.
          Harking back to the very first days of Afterpay, the site had not been
          engineered for load and would crash as soon as traffic spiked. Given
          Afterpay’s relentless growth, everyone knew the situation needed to be
          addressed.
        </p>
        <p
          className={`animated mb-12 delay-500ms ${
            inView ? 'fadeIn' : 'fadeOut'
          }`}
        >
          At this point in time, Afterpay.com.au was a much simpler product than
          it is today. Paloma knew that they could rebuild the web platform in
          a way that would be beneficial long-term, but they also knew that
          Afterpay had little interest in pausing for an uncertain period of
          time. Thus there was a conundrum, Paloma knew they could fix this
          problem for Afterpay;
        </p>
      </div>
      <PolyCluster className="hidden xl:block" />
    </div>
  );
};
