import React from 'react';

import DovetailLogo from './logos/dovetail';

import loadLinkedinImage, {
  linkedInEvents,
} from '../utils/load-linkedin-image';

export default () => (
  <a
    href="https://palomagroup.com"
    className="inline-flex text-ash-black hover:text-navy hover:bg-special-red"
    onClick={() => loadLinkedinImage(linkedInEvents.dovetailHome)}
  >
    <DovetailLogo />
  </a>
);
