import React from 'react';

import Chapter0 from '../pages/chapters/00-home';
import Chapter1 from '../pages/chapters/01-origins';
import Chapter2 from '../pages/chapters/02-technology';
import Chapter3 from '../pages/chapters/03-dovetail';
import Chapter4 from '../pages/chapters/04-scaling';
import Chapter5 from '../pages/chapters/05-growth';
import Chapter6 from '../pages/chapters/06-expansion';
import Chapter7 from '../pages/chapters/07-partnership';

const chapters = {
  '00-home': Chapter0,
  '01-origins': Chapter1,
  '02-technology': Chapter2,
  '03-dovetail': Chapter3,
  '04-scaling': Chapter4,
  '05-growth': Chapter5,
  '06-expansion': Chapter6,
  '07-partnership': Chapter7,
};

export default React.forwardRef(({ id, active }, ref) => {
  const TagName = chapters[id];

  return (
    <TagName id={id} className={`${active ? 'active' : ''}`} forwardRef={ref} />
  );
});
