import React from 'react';

import StickyScroller from '../../components/sticky-scroller';
import Sydney from '../../images/04-scaling/skyline-sydney@2x.png';
import Sun from '../../images/04-scaling/sun.inline.svg';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const Skyline = () => {
  return (
    <StickyScroller>
      {({ inViewRatio, rect, height, scrollProgress = 0 }) => {
        const mobile = isMobile();

        const starProgress = mobile ? 0 : scrollProgress;

        return (
          <div
            id="skyline"
            className="relative h-screen bg-navy overflow-hidden"
          >
            <div className="relative flex h-full ml-auto w-full xl:w-3/4">
              <div
                className="absolute pin overflow-hidden"
                style={{
                  background: `linear-gradient(to bottom, #143673 0%, #143673 ${scrollProgress *
                    35}%, #ff5a7d 70%, #c99cef 100%)`,
                }}
              />
              <svg
                className="absolute pin-t pin-x mx-auto -mt-20"
                width="395"
                height="357"
                viewBox="0 0 395 357"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  opacity: scrollProgress,
                }}
              >
                <path
                  d="M128.056 309.402L107.822 301.305L124.951 287.83L128.056 309.402Z"
                  stroke="#143673"
                  strokeWidth="2"
                  transform={`translate(0 ${starProgress * 40})`}
                />
                <path
                  d="M153.031 185.736L158.004 176.331L163.663 185.34L153.031 185.736Z"
                  fill="#C99CEF"
                  transform={`translate(0 ${starProgress * 50})`}
                />
                <path
                  d="M4.03147 109.736L9.00407 100.331L14.6633 109.34L4.03147 109.736Z"
                  fill="#B1F8FC"
                  transform={`translate(0 ${starProgress * 60})`}
                />
                <path
                  d="M226.165 131.421L231.443 129.785L230.221 135.174L226.165 131.421Z"
                  stroke="#B1F8FC"
                  strokeWidth="2"
                  transform={`translate(0 ${starProgress * 70})`}
                />
                <path
                  d="M277.47 294.111L282.748 292.476L281.525 297.865L277.47 294.111Z"
                  stroke="#B1F8FC"
                  strokeWidth="2"
                  transform={`translate(0 ${starProgress * 30})`}
                />
                <path
                  d="M282.091 119.35L279.998 114.51L285.236 115.117L282.091 119.35Z"
                  fill="#B1F8FC"
                  transform={`translate(0 ${starProgress * 20})`}
                />
                <path
                  d="M390.788 20.3998L388.695 15.5594L393.933 16.1668L390.788 20.3998Z"
                  fill="#B1F8FC"
                  transform={`translate(0 ${starProgress * 10})`}
                />
                <path
                  d="M354.34 208.805L349.847 206.042L354.486 203.533L354.34 208.805Z"
                  fill="#C99CEF"
                  transform={`translate(0 ${starProgress * 20})`}
                />
                <path
                  d="M139.34 6.80452L134.847 4.04196L139.486 1.53296L139.34 6.80452Z"
                  fill="#C99CEF"
                  transform={`translate(0 ${starProgress * 30})`}
                />
              </svg>

              <div
                className="sun"
                style={{
                  transform: `translateZ(0) translateY(${scrollProgress *
                    170}%) translateX(${scrollProgress * -100}%) scale(${1 +
                    scrollProgress / 1.8})`,
                }}
              >
                <Sun width="100%" height="100%" className="block" />
              </div>
              <img
                src={Sydney}
                className="relative flex-no-shrink max-w-0 block ml-auto -mb-4 self-end"
                style={{
                  maxWidth: 'none',
                  width: '81.3260869565vh',
                  height: '30vh',
                  transform: 'translateZ(0)',
                }}
                alt=""
              />
            </div>

            <div className="absolute pin-x pin-t pin-l mt-20 lg:mt-24 xl:mt-32 md:ml-20 lg:ml-48 xl:ml-56">
              <div
                className={`absolute max-w-sm px-6 animated ${
                  inViewRatio >= 0.1 && inViewRatio < 0.45
                    ? 'fadeIn'
                    : 'fadeOut'
                }`}
              >
                <p className="body-text text-white">
                  Over a beer on a balmy evening in Sydney, the team came up
                  with a plan for how they could migrate the web platform to an
                  improved, highly-scalable tech stack. The problem was time.
                  The next meeting with Afterpay was only four days away, and
                  they had little way to prove that this was the correct
                  direction for Afterpay to take. Unless...
                </p>
              </div>
              <div
                className={`absolute px-6 animated ${
                  inViewRatio >= 0.5 && inViewRatio < 0.8 ? 'fadeIn' : 'fadeOut'
                }`}
                style={{ maxWidth: '53rem' }}
              >
                <p className="highlight-text text-navy mb-16">
                  <span className="bg-turquoise">
                    “Could we build a proof of concept capable of handling millions of visitors before the next meeting?”
                  </span>
                </p>
                <p className="highlight-text text-navy">
                  <span className="bg-turquoise">
                    “We have 72 hours of productive time between now and Wednesday...”
                  </span>{' '}
                  <span className="text-turquoise">Said Ash</span>
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </StickyScroller>
  );
};

export default LazyLoadWrap(Skyline, '200vh');
