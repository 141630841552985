export default function() {
  if (typeof document !== 'undefined') {
    const el = document.createElement('span'), mStyle = el.style;

    mStyle.cssText =
      'position:sticky;position:-webkit-sticky;';

    return mStyle.position.indexOf('sticky') !== -1;
  }

  return true;
};
