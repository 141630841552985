import React from 'react';
import { useInView } from 'react-intersection-observer';

import Polycluster from '../../images/01-origins/billion-polycluster.inline.svg';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '0px 0px -12.5% 0px',
    triggerOnce: true,
  });

  return (
    <div className="py-48 lg:py-64 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48 overflow-hidden">
      <div
        id="billion-section"
        ref={ref}
        className={`max-w-lg mx-auto ${inView ? 'in-view' : ''}`}
      >
        <div className="flex">
          <div className="relative w-1/4 self-end hidden lg:block">
            <div
              className="absolute pin-l pin-b bg-neon-lavender rounded-full -ml-40 -mb-10"
              style={{ width: 224, height: 224 }}
            >
              <div
                className={`big-stat-text text-white text-center animated delay-1s ${
                  inView ? 'fadeIn' : 'fadeOut'
                }`}
                style={{ lineHeight: '224px' }}
              >
                25
              </div>
            </div>
            <div
              className={`uppercase text-navy big-stat-text animated delay-500ms ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
              style={{
                transform: `rotate(-90deg) ${inView ? 'translateX(-100%)' : 'translateX(-200%)'}`,
                transformOrigin: 'top left',
                transition: 'transform 1s 500ms'
              }}
            >
              $Billon
            </div>
          </div>
          <div id="billion-text" className="relative lg:w-3/4">
            <Polycluster className="absolute pin-t pin-x mx-auto lg:mr-auto pin-l -mt-40 lg:ml-64" />
            <p
              className={`highlight-text-expanded text-navy -mb-16 animated ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              Together with Anthony Eisen, Nick Molnar set about to{' '}
              <span className="bg-turquoise">create Afterpay,</span> a firm that
              would revolutionise the online payments industry and{' '}
              <span className="bg-turquoise">within 5 years</span> be valued at over{' '}
              <span className="bg-turquoise">$25 billion</span>,
              have over <span className="bg-turquoise">10 million</span>{' '}
              active customers and be quickly expanding across NZ, UK and the US.
              <br />
              <br /> This is the story behind the story of the{' '}
              <span className="bg-turquoise">rise of Afterpay.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
