import React from 'react';
import { useInView } from 'react-intersection-observer';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      className="relative w-full"
      style={{
        background:
          'linear-gradient(0deg, #FF5A7D -11.01%, #143673 100%), #143673',
      }}
    >
      <div
        className="relative ml-auto"
        style={{
          width: '40vh',
          right: '2vw',
          transform: 'translateX(-100%) scaleX(1.555555556)',
        }}
      >
        <div
          className={`vapor-fade-in relative z-10 ${inView ? 'in-view' : ''}`}
          ref={ref}
        >
          <svg
            className="block"
            width="100%"
            height="100%"
            viewBox="0 0 551 279"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g style={{ mixBlendMode: 'overlay' }} opacity="0.2">
              <rect
                x="550.764"
                y="279"
                width="550"
                height="38.1651"
                transform="rotate(-180 550.764 279)"
                fill="#B1F8FC"
              />
              <rect
                x="550.764"
                y="224.385"
                width="550"
                height="37.507"
                transform="rotate(-180 550.764 224.385)"
                fill="#B1F8FC"
              />
              <rect
                x="550.764"
                y="170.427"
                width="550"
                height="27.6368"
                transform="rotate(-180 550.764 170.427)"
                fill="#B1F8FC"
              />
              <rect
                x="550.764"
                y="126.34"
                width="550"
                height="20.3986"
                transform="rotate(-180 550.764 126.34)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.9"
                x="550.764"
                y="92.7808"
                width="550"
                height="15.1344"
                transform="rotate(-180 550.764 92.7808)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.8"
                x="550.764"
                y="69.092"
                width="550"
                height="11.8443"
                transform="rotate(-180 550.764 69.092)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.6"
                x="550.764"
                y="50.0098"
                width="550"
                height="7.2382"
                transform="rotate(-180 550.764 50.0098)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.6"
                x="550.764"
                y="38.165"
                width="550"
                height="4.60613"
                transform="rotate(-180 550.764 38.165)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.5"
                x="550.764"
                y="29.6111"
                width="550"
                height="3.94811"
                transform="rotate(-180 550.764 29.6111)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.3"
                x="550.764"
                y="21.0566"
                width="550"
                height="2.63207"
                transform="rotate(-180 550.764 21.0566)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.3"
                x="550.764"
                y="13.8186"
                width="550"
                height="1.97406"
                transform="rotate(-180 550.764 13.8186)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.2"
                x="550.764"
                y="6.58032"
                width="550"
                height="1.31604"
                transform="rotate(-180 550.764 6.58032)"
                fill="#B1F8FC"
              />
              <rect
                opacity="0.1"
                x="550.764"
                y="0.658203"
                width="550"
                height="0.658018"
                transform="rotate(-180 550.764 0.658203)"
                fill="#B1F8FC"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
