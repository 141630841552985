import React from 'react';

import StickyScroller from '../../components/sticky-scroller';
import Triangle from '../../images/01-origins/walking-triangle.inline.svg';
import Polycluster from '../../images/01-origins/walking-polycluster.inline.svg';
import LazyLoadWrap from '../lazy-load-wrap';

const Walking = () => {
  return (
    <StickyScroller>
      {({ topProgress }) => {
        return (
          <div id="nick-walking">
            <div className="bg w-full lg:w-3/4 overflow-hidden">
              <div className="gradient">
                <Polycluster className="hidden lg:block absolute pin-t pin-l mt-32 ml-48" />
                <div className="absolute w-1/2 pin-x m-auto pin-b">
                  <Triangle
                    className="block"
                    width="100%"
                    height="100%"
                    bottom={0}
                  />
                </div>
                <div
                  id="walking-sprite"
                  className={`absolute pin-b overflow-hidden ${
                    topProgress >= 0.5 && topProgress < 2.5
                      ? 'walk-l'
                      : ''
                  } ${
                    topProgress >= 2.5
                      ? 'walk-r'
                      : ''
                  }`}
                />
              </div>
            </div>
            <div className="pt-10 sm:pt-12 md:pt-20 lg:pt-24 px-6 sm:px-12 md:px-20 lg:pl-40 xl:pl-48">
              <div className="relative z-10 max-w-sm body-text pin-x lg:ml-auto text-navy">
                <p
                  className={`absolute animated ${
                    topProgress >= 0.5 && topProgress < 1.5
                      ? 'fadeIn'
                      : 'fadeOut'
                  }`}
                >
                  Days later, Anthony noticed the young university student
                  approaching on the footpath – his arms full of packages – and
                  stopped him to ask what was keeping him up so late. This
                  serendipitous meeting would prove to be the start of a long
                  fruitful partnership that would produce
                  <span
                    className={`${
                      topProgress >= 0.5 && topProgress < 1.5
                        ? 'fadeIn'
                        : 'fadeOut'
                    } animated delay-500ms highlight-text text-navy block mt-8 lg:mt-16`}
                  >
                    <span className="bg-neon-lavender">one of Australia’s</span>
                    <br />
                    <span className="bg-neon-lavender">
                      most successful tech
                    </span>
                    <br />
                    <span className="bg-neon-lavender">companies to date.</span>
                  </span>
                </p>
                <p
                  className={`absolute animated ${
                    topProgress >= 1.5 && topProgress < 2.5
                      ? 'fadeIn'
                      : 'fadeOut'
                  }`}
                >
                  The industrious neighbour was future Afterpay CEO Nick Molnar.
                  He’d been staying up late to pack jewellery orders that he’d
                  been selling online, he explained. In fact he’d become so
                  successful at selling jewellery online that he was quickly
                  becoming one of the largest jewellery sellers on Ebay. Nick
                  approached an American jewellery retailer, ice.com, and
                  convinced them to let him launch iceonline.com.au.
                  <span
                    className={`${
                      topProgress >= 1.5 && topProgress < 2.5
                        ? 'fadeIn'
                        : 'fadeOut'
                    } animated delay-500ms highlight-text text-navy block mt-8 lg:mt-16`}
                  >
                    <span className="bg-neon-lavender">Before long, Nick</span>
                    <br />
                    <span className="bg-neon-lavender">
                      Molnar’s iceonline.com.au
                    </span>
                    <br />
                    <span className="bg-neon-lavender">
                      was bringing in $2m of
                    </span>
                    <br />
                    <span className="bg-neon-lavender">sales a year.</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </StickyScroller>
  );
};

export default LazyLoadWrap(Walking, '200vh');
