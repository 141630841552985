import React, { useState } from 'react';

import DovetailLogo from './dovetail-logo';
import AfterpayLogo from './logos/afterpay';
import MenuToggle from './ui/menu-toggle';
import useToggleable from '../hooks/useToggleable';

export default ({ chapters, activeChapterId, setActiveChapter, toggleForm }) => {
  const [show, toggle] = useToggleable();
  const [inTransition, setInTransition] = useState(false);

  const onClick = e => {
    e.preventDefault();

    const hash = e.currentTarget.href.split('#')[1];
    const { offsetTop } = document.getElementById(hash);

    setInTransition(true);
    if (show) toggle();

    setTimeout(() => {
      window.scrollTo(0, offsetTop);
      setInTransition(false);
    }, 500);
  };

  return (
    <div id="nav-wrap" className={`${inTransition ? 'in-transition' : ''}`}>
      <div className="hidden lg:block">
        <nav
          id="nav"
          className={`${show ? 'show' : ''} ${
            activeChapterId !== '00-home' ? 'in-view' : ''
          }`}
        >
          <div className="relative clearfix">
            <div className="dovetail-logo-container">
              <DovetailLogo />
            </div>
            <div className="mt-32 mb-12 ml-1">
              <MenuToggle show={show} handleClick={toggle} />
            </div>
          </div>
          <div className="relative">
            <ol id="nav-links" className="list-reset">
              {chapters.map(chapter => (
                <li key={chapter.id} className="my-4">
                  <a
                    href={`#${chapter.id}`}
                    className={`${
                      activeChapterId === chapter.id ? 'active' : ''
                    }`}
                    onClick={onClick}
                  >
                    <span className="mr-1">0{chapter.number}.</span>{' '}
                    {chapter.name}
                  </a>
                </li>
              ))}
            </ol>
          </div>
          <div className="afterpay-logo-container">
            <a
              href="https://afterpay.com"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <AfterpayLogo width={116} />
            </a>
          </div>
        </nav>
      </div>
      <div className="lg:hidden">
        <div
          id="nav-mobile-overlay"
          className={`fixed pin z-50 bg-white animated ${
            show ? 'fadeIn' : 'fadeOut'
          }`}
        >
          <div className="relative h-full">
            <div className="h-full flex justify-center">
              <ol id="nav-links" className="list-reset self-center">
                {chapters.map(chapter => (
                  <li key={chapter.id} className="text-lg my-4">
                    <a
                      href={`#${chapter.id}`}
                      className={`${
                        activeChapterId === chapter.id ? 'active' : ''
                      }`}
                      onClick={onClick}
                    >
                      <span className="mr-1">0{chapter.number}.</span>{' '}
                      {chapter.name}
                    </a>
                  </li>
                ))}
              </ol>
            </div>
            <div className="flex flex-col p-4 absolute pin-x pin-b">
              <button
                className="btn w-full bg-special-red hover:bg-neon-lavender text-white text-center text-sm py-3 px-5"
                onClick={toggleForm}
              >
                Work with Paloma
              </button>
            </div>
          </div>
        </div>
        <nav
          id="nav-mobile"
          className={`fixed flex items-center justify-between w-full z-50 py-2 px-3 ${
            show ? 'show' : ''
          } in-view`}
        >
          <div className="dovetail-logo-container">
            <DovetailLogo />
          </div>
          <MenuToggle show={show} handleClick={toggle} />
        </nav>
      </div>
    </div>
  );
};
