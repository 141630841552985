import React from 'react';
import { useInView } from 'react-intersection-observer';

import TriangleIsosceles from '../../images/triangle-isosceles.inline.svg';
import Gradients from '../../images/gradients.inline.svg';
import CircleSVG from '../../images/circle.inline.svg';

import isMobile from '../../utils/is-mobile';

export default () => {
  const [refCircle, inViewCircle] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [refBigCircle, inViewBigCircle] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [refTriangle, inViewTriangle] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const mobile = isMobile();

  return (
    <div className="bg-white">
      <div
        ref={refCircle}
        className="absolute pin-x overflow-hidden"
        style={{ height: '40vh' }}
      >
        <div
          className="absolute"
          style={{
            width: '40vh',
            right: '2vw',
            transform: `translateZ(0) translateX(-100%) translateY(${
              inViewCircle ? '-56' : '-128'
            }%) scale(1.555555556)`,
            transition: 'transform 500ms 550ms',
          }}
        >
          <CircleSVG width="100%" className="text-turquoise" />
        </div>
      </div>
      <div className="max-w-2xl mx-auto">
        <div className="relative max-w-xl ml-auto pt-32 px-6 sm:px-12 md:px-20">
          <p
            className={`body-text text-navy text-right animated delay-1s ${
              inViewCircle ? 'fadeIn' : 'fadeOut'
            }`}
          >
            The idea was on the verge of feasibility - it would require
            around-the-clock development and there was no room for error.
            Paloma thought they had a chance, and over the following three
            days and nights, the team got to work building a sophisticated
            high-traffic prototype of Afterpay’s web platform. On Wednesday
            morning, bleary eyed, they headed into Afterpay’s Sydney office.
          </p>
          <div className="h-64 ml-12 mt-12 pb-12 lg:pb-0 mb-32">
            <div
              className={`w-px bg-special-red ${
                inViewBigCircle ? 'h-full' : 'h-0'
              }`}
              style={{
                transition: 'height 250ms',
              }}
            />
          </div>
        </div>
        <div className="relative lg:ml-20 mx-auto -mt-48 mb-32 overflow-hidden">
          <div id="big-red-circle" className="relative ml-auto -mr-20 sm:mr-16">
            <div
              ref={refBigCircle}
              className={`absolute pin text-special-red animated delay-500ms ${
                inViewBigCircle ? 'fadeIn' : 'fadeOut'
              }`}
            >
              <CircleSVG className="block" />
            </div>
            {!mobile && (
              <div
                className="absolute pin text-navy"
                style={{
                  opacity: `${inViewBigCircle ? 1 : 0}`,
                  transform: `translateZ(0) rotate(${
                    inViewBigCircle ? 180 : -180
                  }deg) translate(7%, 7%)`,
                  transition: 'transform 1s, opacity 500ms',
                }}
              >
                <CircleSVG width={90} />
              </div>
            )}
          </div>
          <p
            className={`absolute pin-x px-6 sm:px-12 md:px-20 xl:px-6 highlight-text max-w-lg text-navy center-y animated delay-500ms ${
              inViewBigCircle ? 'fadeIn' : 'fadeOut'
            }`}
          >
            Paloma opened a browser, screencast onto a TV, and explained to{' '}
            <span
              className={`transition-bg transition-1s transition-delay-1s ${
                inViewBigCircle ? 'bg-turquoise' : 'bg-transparent'
              }`}
            >
              Afterpay they were looking at a new and improved version of their
              web application - built to handle the aggressive international
              growth strategy that Afterpay would soon pursue.
            </span>
          </p>
        </div>
        <Gradients className="w-0 h-0" />
        <div
          id="live-demo"
          className={`px-6 sm:px-12 md:px-20 ${
            inViewTriangle ? 'in-view' : ''
          }`}
        >
          <div className="flex lg:max-w-lg mx-auto ">
            <div id="browser-sprite" className="mt-16 flex-no-shrink" />
            <div
              id="triangle-gradient"
              className="w-1/2 -ml-16 lg:ml-0 mt-16 lg:mt-0"
              ref={refTriangle}
            >
              <TriangleIsosceles
                id="live-demo-triangle-turquoise"
                className="block text-turquoise"
              />
              <div className="absolute pin -ml-20 lg:-ml-56 overflow-hidden">
                <div
                  id="nick-talking-sprite"
                  className="absolute pin-b -mb-8"
                />
              </div>
              <TriangleIsosceles id="live-demo-triangle-down-gradient" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
