import React from 'react';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';
import Mountains from '../../images/04-scaling/hero-mountains.inline.svg';
import ScrollTracker from '../scroll-tracker';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile ? 0 : top < height && top > -height ? top : 0;

        let topProgress;

        // start from 50% inview e.g. top * 2
        if (!mobile) {
          topProgress = (height - top * 2) / height;
          topProgress = topProgress >= 1 ? 1 : topProgress || 1;
        } else {
          topProgress = 1;
        }


        return (
          <div className="hero">
            <div
              className="bg"
              style={{
                background: `linear-gradient(0deg, #C99CEF 0%, #C99CEF 0%, #143673 ${60 - (topProgress / 2) * 20}%)`,
              }}
            >
              <div className="mountains-container">
                <Mountains className="block -mb-4" width="100%" height="100%" />
              </div>
            </div>
            <div className="hero-text-container">
              <HeroText
                date="June 2017 – December 2017"
                lineA="Scaling"
                lineB="Up"
              />
              <HighlightTextHeading
                bgColor="special-red"
                textColor="navy"
                lineColor="special-red"
              >
                Growing pains
              </HighlightTextHeading>
            </div>
            <svg
              className="block absolute pin-t pin-r mr-12"
              width="920"
              height="686"
              viewBox="0 0 920 686"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ opacity: `${topProgress}` }}
            >
              <path
                d="M776.019 374.149L771.927 362.836L783.771 364.948L776.019 374.149Z"
                fill="#C99CEF"
                transform={`translate(0 ${offsetY / 5})`}
              />
              <path
                d="M673.735 216.543L676.379 210.881L679.961 216.002L673.735 216.543Z"
                stroke="#C99CEF"
                strokeWidth="2"
                transform={`translate(0 ${offsetY / 6})`}
              />
              <path
                d="M795.642 170.623L789.999 168.695L794.49 164.771L795.642 170.623Z"
                fill="#B1F8FC"
                transform={`translate(0 ${offsetY / 10})`}
              />
              <path
                d="M534.68 221.542L529.037 219.614L533.528 215.691L534.68 221.542Z"
                fill="#B1F8FC"
                transform={`translate(0 ${offsetY / 11})`}
              />
              <path
                d="M876.755 226.728L871.027 228.388L872.453 222.598L876.755 226.728Z"
                fill="#C99CEF"
                transform={`translate(0 ${offsetY / 10})`}
              />
              <path
                d="M719.273 50.015L713.545 51.6749L714.972 45.8844L719.273 50.015Z"
                fill="#C99CEF"
                transform={`translate(0 ${offsetY / 9})`}
              />
              <path
                d="M53.4055 296.105L49.1778 292.546L54.3737 290.664L53.4055 296.105Z"
                stroke="#B1F8FC"
                strokeWidth="2"
                transform={`translate(0 ${offsetY / 7})`}
              />
              <path
                d="M162.612 165.055L158.384 161.496L163.58 159.614L162.612 165.055Z"
                stroke="#B1F8FC"
                strokeWidth="2"
                transform={`translate(0 ${offsetY / 8})`}
              />
              <path
                d="M429.973 88.1434L427.03 92.5195L424.712 87.7828L429.973 88.1434Z"
                fill="#B1F8FC"
                transform={`translate(0 ${offsetY / 10})`}
              />
              <path
                d="M287.988 50.1122L285.045 54.4883L282.727 49.7515L287.988 50.1122Z"
                fill="#B1F8FC"
                transform={`translate(0 ${offsetY / 11})`}
              />
              <path
                d="M412.362 269.07L412.463 274.343L407.846 271.794L412.362 269.07Z"
                fill="#C99CEF"
                transform={`translate(0 ${offsetY / 9})`}
              />
            </svg>
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero);
