import React from 'react';
import SEO from './seo';
import isSticky from '../utils/csspositionsticky';
import isIE from '../utils/is-ie';
import useToggleable from '../hooks/useToggleable';

import Dovetail from '../images/00-home/dovetail.inline.svg';
import Afterpay from '../images/00-home/afterpay.inline.svg';

import EnquiryForm from './enquiry-form';

export default ({ children }) => {
  const [hideStickyPrompt, toggleStickyPrompt] = useToggleable();
  const [showForm, toggleForm] = useToggleable();

  return (
    <React.Fragment>
      <SEO title="The Story of Paloma and Afterpay" />
      <button
        className="hidden fixed focus:outline-none lg:block pin-t pin-r btn bg-special-red text-white py-3 px-5 mt-4 mr-4 z-50 hover:bg-neon-lavender"
        onClick={toggleForm}
      >
        Work with Paloma
      </button>
      <div>
        {children({
          toggleForm,
        })}
      </div>
      <div id="loading-overlay">
        <div
          className="absolute"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        >
          <div className="flex items-center" style={{ width: 200 }}>
            <div
              style={{
                width: 100,
                height: 93,
                transform: 'rotate(-90deg) translateY(19%)',
              }}
            >
              <Dovetail id="dovetail-d" width="100%" height="100%" />
            </div>
            <div
              style={{
                width: 100,
                height: 89,
                transform: 'rotate(-90deg) translateY(-19%)',
              }}
            >
              <Afterpay id="afterpay-a" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
      {showForm && (
        <div
          className="fixed pin lg:flex lg:items-center z-50 overflow-scroll"
          style={{ background: '#c99cefd9' }}
        >
          <div className="absolute pin" onClick={toggleForm} />
          <div className="relative max-w-lg mx-auto w-full">
            <EnquiryForm />
          </div>
        </div>
      )}
      {(isIE() || !isSticky()) && !hideStickyPrompt && (
        <div className="fixed pin bg-special-red z-50">
          <div className="flex justify-center h-full">
            <div className="max-w-md px-6 self-center">
              <h1 className="big-stat-text text-white mb-8">Uh Oh!</h1>
              <p className="highlight-text text-white mb-6">
                It appears the browser you are using is a bit old school and
                doesn't support some of the cool features this site has to
                offer...
              </p>
              <div className="text-ash-black stat-heading-text text-lg no-whitespace">
                <button
                  className="text-special-red stat-heading-text text-lg no-underline cursor-pointer inline-block bg-white hover:bg-ash-black p-2"
                  onClick={toggleStickyPrompt}
                >
                  View anyway?
                </button>
                <span className="text-white inline-block mx-4">or</span>
                <a
                  href="https://palomagroup.com"
                  className="text-special-red no-underline cursor-pointer inline-block bg-white hover:bg-ash-black p-2"
                >
                  Go to Paloma Group
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
