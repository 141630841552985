import React from 'react';

export default ({ show, handleClick }) => (
  <button
    className={`menu-toggle ${show ? 'show' : ''}`}
    type="button"
    onClick={handleClick}
  >
    <span />
    <span />
    <span />
  </button>
);
