import React from 'react';

export default class ChaptersController extends React.Component {
  constructor(props) {
    super(props);

    this.singleRefs = props.chapters.reduce((acc, value) => {
      acc[value.id] = {
        ref: React.createRef(),
        id: value.id,
        ratio: 0,
      };
      return acc;
    }, {});

    const callback = entries => {
      entries.forEach(
        entry =>
          (this.singleRefs[entry.target.id].ratio = entry.intersectionRatio)
      );

      const activeChapter = Object.values(this.singleRefs).reduce(
        (acc, value) => (value.ratio > acc.ratio ? value : acc),
        this.props.activeChapter
      );

      if (activeChapter.ratio > this.props.activeChapter.ratio) {
        this.props.setActiveChapter(activeChapter);
      }
    };

    if (typeof window !== 'undefined' && window.IntersectionObserver) {
      this.observer = new IntersectionObserver(callback, {
        threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
      });
    }
  }

  componentDidMount() {
    Object.values(this.singleRefs).forEach(value =>
      this.observer.observe(value.ref.current)
    );
  }

  render() {
    return this.props.children({
      refs: this.singleRefs,
    });
  }
}
