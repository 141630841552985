import React from 'react';
import { useInView } from 'react-intersection-observer';

import PolyCluster from '../../images/04-scaling/prove-it-polycluster.inline.svg';

export default () => {
  const [refBut, inViewBut] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refCould, inViewCould] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refProve, inViewProve] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="relative -mt-64">
      <div className="absolute pin-y pin-r bg-turquoise w-1/2 md:w-3/4" />
      <div className="relative py-32 lg:py-48 px-6 sm:px-12 md:px-20 lg:pl-48 lg:pr-12">
        <div className="big-stat-text text-special-red py-20">
          <div ref={refBut} className={`my-4 animated ${inViewBut ? 'fadeIn' : 'fadeOut'}`}>But How</div>
          <div ref={refCould} className={`my-4 animated ${inViewCould ? 'fadeIn' : 'fadeOut'}`}>Could They</div>
          <div ref={refProve} className={`my-4 animated ${inViewProve ? 'fadeIn' : 'fadeOut'}`}>Prove this?</div>
        </div>
        <PolyCluster className="block mx-auto lg:ml-0" />
      </div>
    </div>
  );
};
