import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Chapters from '../components/chapters';
import ChaptersController from '../components/chapters-controller';
import Chapter from '../components/chapter';
import Nav from '../components/nav';

export default () => {
  const {
    site: {
      siteMetadata: { chapters },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            chapters {
              id
              number
              name
              year
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      {({ showForm, toggleForm }) => (
        <Chapters>
          {({ activeChapter, setActiveChapter }) => (
            <React.Fragment>
              <Nav
                activeChapterId={activeChapter.id}
                chapters={chapters}
                setActiveChapter={setActiveChapter}
                toggleForm={toggleForm}
              />
              <ChaptersController
                chapters={chapters}
                activeChapter={activeChapter}
                setActiveChapter={setActiveChapter}
              >
                {({ refs }) => (
                  <main>
                    {chapters.map(chapter => (
                      <Chapter
                        key={chapter.id}
                        id={chapter.id}
                        active={activeChapter.id === chapter.id}
                        ref={refs[chapter.id].ref}
                      />
                    ))}
                    <div className="chapter-overlay" />
                  </main>
                )}
              </ChaptersController>
            </React.Fragment>
          )}
        </Chapters>
      )}
    </Layout>
  );
};
