import React from 'react';

import Hero from '../../components/04-scaling/hero';
import Intro from '../../components/04-scaling/intro';
import ProveIt from '../../components/04-scaling/prove-it';
import Skyline from '../../components/04-scaling/skyline';
import VaporSpacer from '../../components/04-scaling/vapor-spacer';
import LiveDemo from '../../components/04-scaling/live-demo';
import Website from '../../components/04-scaling/website';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <div className="nav-text">
        <div>04. Scaling Up</div>
      </div>
      <Hero />
      <Intro />
      <ProveIt />
      <Skyline />
      <VaporSpacer />
      <div className="overflow-hidden">
        <LiveDemo />
        <Website />
      </div>
    </div>
  );
};
