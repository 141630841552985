import React from 'react';
import { useInView } from 'react-intersection-observer';
import ScrollTracker from '../scroll-tracker';

import MoonRedNavy from '../../images/01-origins/afterpay-moon-red-navy.inline.svg';
import AfterpayWhite from '../../images/01-origins/afterpay-afterpay-white.inline.svg';
import VaporRed from '../../images/01-origins/afterpay-vapor-red.inline.svg';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '0px 0px -12.5% 0px',
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top } }) => (
        <div className="relative bg-navy overflow-hidden">
          <div
            className="absolute pin-t pin-b pin-l w-full lg:w-3/5"
            style={{
              background: 'linear-gradient(0deg, #143673 50%, #FF5A7D 100%)',
              transform: `translateZ(0) translateY(${(-top || 0) / 10}px)`,
            }}
          >
            <VaporRed width="100%" preserveAspectRatio="none" top={0} />
          </div>
          <div
            className="absolute pin-b pin-x"
            style={{ transform: 'translateY(50%)' }}
          >
            <MoonRedNavy className="block mx-auto lg:-mr-20" />
          </div>
          <div className="relative z-10 text-center py-64 px-6 sm:px-12 md:px-20 lg:px-40">
            <div className="max-w-lg mx-auto">
              <div ref={ref} className="mt-40">
                <AfterpayWhite
                  width="100%"
                  className={`animated ${
                    inView ? 'fadeInUpSmall' : 'fadeOutUpSmall'
                  }`}
                />
              </div>
              <p
                className={`body-text text-white mt-16 animated delay-250ms ${
                  inView ? 'fadeInUpSmall' : 'fadeOutUpSmall'
                }`}
              >
                Out of this frustration the idea for Afterpay was born. Why not
                allow customers to buy products online on lay-by, and pay them off
                over time? Better yet, why not make it interest-free and have the
                merchant pay a small fee in return for increased sales?
                Intuitively it made sense to Nick that this would work, and early
                experiments with his own jewellery site proved that this could be
                an incredibly powerful tool to increase conversion rates and
                sales.
              </p>
            </div>
          </div>
        </div>
      )}
    </ScrollTracker>
  );
};
