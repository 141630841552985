import React from 'react';

const Input = ({
  elementType,
  value,
  changed,
  elementConfig,
  invalid,
  shouldValidate,
  label,
  touched,
}) => {
  let inputElement = null;
  const inputClasses = [
    'block',
    'w-full',
    'border',
    'border-ash-black',
    'px-3',
    'py-2',
  ];

  if (invalid && shouldValidate && touched) {
    inputClasses.push('border-special-red');
  }

  switch (elementType) {
    case 'input':
      inputElement = (
        <input
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          className={`${inputClasses.join(' ')} h-32`}
          {...elementConfig}
          value={value}
          onChange={changed}
          data-gramm_editor="false"
        />
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
      break;
  }

  return (
    <div className="px-4 mb-8">
      <label className="mb-2 block font-sm">{label}</label>
      {inputElement}
    </div>
  );
};

export default Input;